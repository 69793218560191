import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Digitaler Konzeptgeber',
        description: 'Anders denken, gewohnte Pfade verlassen, offen an innovative Technologien herangehen. Wir erweitern Ihr Unternehmen um eine digitale Komponente.'
    },
    {
        icon: '02',
        title: 'Systemintegration',
        description: 'Wir optimieren Ihre Effizienz durch die Einbindung von innovativen Systemen, die Ihre Workflows erleichtern. Dazu setzen wir auf die Marktführer der Branche oder auch Individualentwicklungen.'
    },
    {
        icon: '03',
        title: 'Operations Management',
        description: 'Wir betreiben Ihre IT, während Sie sich voll und ganz auf den Kernbereich Ihres Businesses konzentrieren. Wir optimieren Ihren Traffic und minimieren die Betriebsstörungen.  '
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
