import React, { Component, Fragment } from "react"
import ScrollToTop from "react-scroll-up"
import Particles from "react-particles-js"
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi"
import ServiceList from "../elements/service/ServiceList"
import BlogContent from "../elements/blog/BlogContent"
import FooterTwo from "../component/footer/FooterTwo"
import Contact from "../elements/contact/Impress"

import SEO from "../components/seo"
import Header from "../component/header/HeaderFour"

import TextLoop from "react-text-loop";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo"
import ServiceWrapper from "../elements/service/ServiceWrapper"
import CounterOne from "../elements/counters/CounterOne"
import Accordion01 from "../elements/Accordion"
import Team from "../blocks/Team"
import TabThree from "../elements/tab/TabThree"
import Lightbox from "react-image-lightbox"
import { TabPanel } from "react-tabs"
import ContactTwo from "../elements/contact/ContactTwo"
import Impress from "../elements/contact/Impress"
import Footer from "../component/footer/Footer"
import Testimonial from "../elements/Testimonial"
import BrandTwo from "../elements/BrandTwo"
import SliderOne from "../component/slider/SliderOne"
import TabsTwo from "../elements/tab/TabTwo"
import TabStyleThree from "../elements/tab/TabThree"
import TabsOne from "../elements/tab/TabOne"


const PortfolioList = [
  {
    image: 'maskenversand',
    name: 'Maskenversand',
    title: 'Der Onlineshop für Arbeitsschutzprodukte'
  },
  {
    image: 'agrano',
    name: 'Agrano Fleet Management GmbH',
    title: 'Effiziente und professionelle Fuhrparkverwaltung'
  },
  {
    image: 'vehicl',
    name: 'VEHICL',
    title: 'VEHICL transformiert den stationären Automobilhandel in ein digitales Geschäftsmodell.'
  },
  {
    image: 'adm',
    name: 'ADM Logistics GmbH',
    title: 'Full-Service-Logistik aus einer Hand.'
  }
]


const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Netzwerk Digitaler Unternehmen",
    description: "Kompetent und professionell unterstützen wir Sie dabei, Ihr Geschäftsmodell um eine digitale Perspektive zu erweitern.",
    buttonText: "Unser Portfolio",
    buttonLink: "#portfolio"
  }
]

class HomeParticles extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    // window.addEventListener('load', function() {
    //   console.log('All assets are loaded');
    // })
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() {
  }

  render() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", function() {
        var value = window.scrollY
        if (value > 100) {
          document.querySelector(".header--fixed").classList.add("sticky")
        } else {
          document.querySelector(".header--fixed").classList.remove("sticky")
        }
      })

      var elements = document.querySelectorAll(".has-droupdown > a")
      for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
          elements[i].onclick = function() {
            this.parentElement.querySelector(".submenu").classList.toggle("active")
            this.classList.toggle("open")
          }
        }
      }
    }

    return (
      <Fragment>
        <div className="active-dark">
        <SEO title="EdelNetzwerk"/>

        {/* Start Header Area  */}
        <Header headerPosition="header--transparent" color="color-white" logo="logo-light"/>
        {/* End Header Area  */}


        {/* Start Slider Area   */}
        <div id="home" className="fix">
          <div className="slider-wrapper">
            {/* Start Single Slide */}
            {SlideList.map((value , index) => (
              <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25 " key={index}>
                <div className="container mb-5 pb-5">
                  <div className="row mb-5 pb-5">
                    <div className="col-lg-12 pb-5 mb-5">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ''}
                        <h1 style={{ color: "#FFF" }} className="title">Ihre<br/>
                          <TextLoop>
                            <span> Web Development</span>
                            <span> E-Commerce</span>
                            <span> Digitalisierungs</span>
                            <span> Brand Building</span>
                            <span> Online Marketing</span>
                            <span> Netzwerk</span>
                            <span> Backup</span>
                            <span> DevOps</span>
                          </TextLoop>{" "}
                        </h1>
                        <h2 style={{ color: "#FFF" }}>Experten aus Düsseldorf.</h2>
                        {value.description ? <p style={{ color: "#FFF" }} className="description">{value.description}</p> : ''}
                        {/*{value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}*/}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* End Single Slide */}
          </div>
        </div>
        {/* End Slider Area   */}

        <div className="slider-wrapper">
          <SliderOne />
        </div>


        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5" id="about">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img className="w-100" src="/assets/images/about/about-4-6.jpg" alt="About Images"/>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Wer rechtzeitig digitalisiert...</h2>
                    <p>und automatisiert, bleibt zukunftssicher. Reagieren Sie auf das Versagen klassischer Geschäftsmodelle wie im Einzelhandel und kommen Sie Ihrer Konkurrenz zuvor.</p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="http://homepage-erstellen-angebot.s3-website.eu-central-1.amazonaws.com/">Angebot anfordern</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}


        <div className="service-area ptb--75  bg_image bg_image--3" id="services">
          <div className="container">
            <ServiceWrapper />
          </div>
        </div>



        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120" id="customers">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}


        {/* Start CounterUp Area */}
        {/*<div className="rn-counterup-area pt--25 pb--110 bg_color--1">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row">*/}
        {/*      <div className="col-lg-12">*/}
        {/*        <div className="section-title text-center">*/}
        {/*          <h3 className="fontWeight500">Our Fun Fafcts</h3>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <CounterOne />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* End CounterUp Area */}


          {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">

                <div className="col-lg-5 col-md-12">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                  </div>
                </div>

                <div className="col-lg-7 col-md-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">Lass uns durchstarten!</h2>
                      <p className="description">Wie wäre es mit einem Erstgespräch, um Ihre persönlichen Bedürfnisse zu analysieren?</p>
                    </div>
                    <div className="row mt--30 mt_sm--10">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title"><a style={{color: '#fff'}} href="mailto:info@edel.digital">info@edel.digital</a></h3>
                          <p>Schreiben Sie doch einfach eine kurze e-Mail mit Ihren Plänen, wir werden dann gerne eine Bedarfsanalyse für Sie durchführen.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title"><a style={{color: '#fff'}} href="tel:004921197635549">0211 / 9763 5549</a></h3>
                          <p>Was können wir für Sie möglich machen? Rufen Sie einfach durch, unser Expertenteam steht Ihnen zur Beratung zur Verfügung.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Us */}


        {/* Start Footer Style  */}
        <Footer/>
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        </div>
      </Fragment>
    )
  }
}

export default HomeParticles
