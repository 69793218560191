import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import ModalVideo from 'react-modal-video';

class SliderOne extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({isOpen: true})
  }
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1  d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-5 d-lg-block">
                            <div className="inner">
                                <h1 className="title theme-gradient">Einfach. <br /> Digital. </h1>
                            </div>
                        </div>

                    </div>
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                    {/* End Service Area */}
                </div>
            </div>
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;
